import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location })  => (
  <Layout>
    <SEO 
        pagetitle="個人情報の取扱いについて"
        pagepath={location.pathname}
    />
    <main id="policy">
        <div className="inner">
            <h1>個人情報の取扱いについて</h1>
            <p>甲能税理士事務所（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。</p>
            <h2 className="privacyPolicy">プライバシーポリシー</h2>
            <h3>1.収集する利用者情報及び収集方法</h3>
            <p>本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。</p>
            <h4>(1)ユーザーからご提供いただく情報</h4>
            <p>本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。</p>
            <div>
                <p>・氏名、生年月日等プロフィールに関する情報</p>
                <p>・メールアドレス、電話番号、住所等連絡先に関する情報</p>
                <p>・入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報</p>
            </div>
            <h4>(2)ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報</h4>
            <p>ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。</p>
            <div>
                <p>・当該外部サービスでユーザーが利用するID</p>
                <p>・その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報</p>
            </div>
            <h4>(3)ユーザーが本サービスを利用するにあたって、当社が収集する情報</h4>
            <p>当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。</p>
            <div>
                <p>・端末の設定言語、地域、機種名、OS,ブラウザバージョン、プロバイダ等の端末識別情報</p>
                <p>・リファラ</p>
                <p>・IPアドレス</p>
                <p>・サーバーアクセスログに関する情報</p>
                <p>・Cookie、ADID、IDFAその他の識別子</p>
            </div>
            <h3>2.利用目的</h3>
            <p>本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。</p>
            <p>(1)	本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため</p>
            <p>(2)	ユーザーのトラフィック測定及び行動測定のため</p>
            <p>(3)	本サービスに関するご案内、お問い合わせ等への対応のため</p>
            <p>(4)	本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</p>
            <p>(5)	本サービスに関する規約等の変更などを通知するため</p>
            <h3>3.外部送信、第三者提供、情報収集モジュールの有無</h3>
            <p>3-1 本サービスでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。</p>
            <p>提携先 Google</p>
            <p><a href="https://policies.google.com/technologies/partner-sites?hl=ja">上記提携先のプライバシーポリシーのURL</a></p>
            <p><a href="https://tools.google.com/dlpage/gaoptout?hl=ja">上記提携先のオプトアウト（無効化）URL</a></p>
            <p>3-2 本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。</p>
            <div className="policyTable">
                <p>(1)情報収集モジュールの名称</p>
                <p>Google Analytics</p>
                <p>(2)情報収集モジュールの提供者</p>
                <p>Google Inc.</p>
                <p>(3)	提供される利用者情報の項目</p>
                <p>第１条(３)に定める利用情報</p>
                <p>(4)提供の手段・方法</p>
                <p><a href="https://support.google.com/analytics/answer/1008015?hl=ja">Google アナリティクス「Google アナリティクスの設定」</a></p>
                <p>(5)上記提供者における利用目的</p>
                <p>本サービスのサービス向上・マーケティング調査の目的でアクセス解析を行うため</p>
                <p>(6)上記提供者における第三者提供の有無</p>
                <p>なし</p>
                <p>(7)上記提供者のプライバシーポリシーのURL</p>
                <p><a href="https://policies.google.com/privacy?hl=ja">Google アナリティクス「プライバシポリシー」</a></p>
            </div>
            <h3>4.第三者提供</h3>
            <p>当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。</p>
            <p>(1)当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</p>
            <p>(2)合併その他の事由による事業の承継に伴って個人情報が提供される場合</p>
            <p>(3)第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合</p>
            <p>(4)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</p>
            <p>(5)その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合</p>
            {/* <h3>5.共同利用</h3>
            <p>当社は、以下のとおりユーザーの個人情報を共同利用します。</p>
            <div className="policyTable">
                <p>(1)共同して利用される個人情報の項目</p>
                <p>第１条(1)に定めるユーザー情報</p>
                <p>(2)共同して利用する者の範囲</p>
                <p>第4条に定める第三者提供の範囲内</p>
                <p>(3)共同して利用する者の利用目的</p>
                <p>第２条に定める利用目的</p>
                <p>(4)個人情報の管理について責任を有する物の名称</p>
                <p>Top up learning(オンラインラーニングプラットフォーム)</p>
                <p>(5)上記共同利用者のプライバシーポリシーのURL</p>
                <p><a href="https://topuplearning.com/privacy-policy/">プライバシーポリシーページ</a></p>
            </div> */}
            <h3>5.個人情報の開示</h3>
            <p>当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。</p>
            <h3>6.個人情報の訂正及び利用停止等</h3>
            <p>6-1 当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。</p>
            <p>6-2 当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。</p>
            <p>6-3 個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、6-1および6-2の規定は適用されません。</p>
            <h3>7.お問い合わせ窓口</h3>
            <p>ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。</p>
            <p>連絡先: kono@kono-office.com</p>
            <h3>8.プライバシーポリシーの変更手続</h3>
            <p>当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。</p>
            <div className="policyDate">2021年5月11日制定</div>
        </div>
        <div className="breadCrumb">
            <ol>
                <li>
                <Link to={`/`}>TOP</Link>
                </li>
                <li>
                    個人情報の取扱い
                </li>
            </ol>
        </div>
    </main>
  </Layout>
)
